body{
  height: 100vh;
  /*color: #1e2125;*/
}

#root{
  height: 100%;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  /*background-color: #212020;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-brand:hover{
  transform: scale(1.1);
  transition: ease 0.5s;
}

.container{
  /*background-color: #1e2125;*/
}

.py-5 > .table {
  /*background-color: slategray;*/
}

.employee_actions{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /*flex-direction: column;*/
}

.navbar-toggler{
  display: none;
}

@media screen and (max-width: 700px) {
  .email, .employee_email{
    width: 10vw;
  }
  .userName, .phoneNumber{
    display: none;
  }
  .employee_userName, .employee_phoneNumber{
    display: none;
  }
}

@media screen and (max-width: 444px) {
  .employee_name, .employee_email{
    font-size: 0.9em;
  }
  .email, .employee_email{
    display: none;
  }
}